import React from 'react';
import { Card, Eyebrow, Page, Stack, Headline, Link, BodyText, Icon, Layout } from '@servicetitan/design-system';
import { tokens } from '@servicetitan/tokens/core';
import { Footer, GlobalWrapper, SEO } from '../components';
import { ResourceLink } from '../components';
import { ScreenSizeContext } from '../context/ScreenSizeContext';
/** Homepage images */
import heroIllustration from '../images/hero.png';
import components from '../images/components.png';
import patterns from '../images/patterns.png';
import foundations from '../images/foundations.png';


// Content adopted from What's New page, /content/resources/whatsnew.mdx
const whatsNew =
	{
		date: 'April 2023', // Month YYYY
		description: 'Updated Content guidance', // Keep <100 characters long
		link: '/resources/whatsnew/' // Shouldn't need to change
	}
;

const hero =
	{
		title: ['Anvil', 'Design System'],
		tagline: 'Build great products, faster.',
		description: 'Anvil empowers product teams to create great experiences for ServiceTitan software with inclusive tools, alignment, and processes.',
		cta: '' // todo: Getting started content, what's new page
	}
;

const offerings = [
	{
		title: 'Foundations',
		description: 'Foundations are the visual, atomic elements that bring together a UI.',
		illustration: foundations,
		maxWidth: '193px',
		top: '-16px',
		left: '-11px',
		link: '/foundations/'
	},
	{
		title: 'Components',
		description: 'Components are reusable building blocks of the UI, crafted from Foundations.',
		illustration: components,
		maxWidth: '148px',
		top: '-17px',
		left: '0',
		link: '/components/'
	},
	{
		title: 'Patterns',
		description: 'Patterns are a collection of Components, arranged to achieve a user goal.',
		illustration: patterns,
		maxWidth: '166px',
		top: '-15px',
		left: '-23px',
		link: '/patterns/'
	},
];

const designResources = [
	{
		title: 'Figma Components',
		subtitle: 'Our official library of Anvil components',
		url: 'https://www.figma.com/file/KLWxYdHAdFNvmt4WjEbCid/Anvil',
		icon: 'description'
	},
	{
		title: 'Illustrations Library',
		subtitle: 'Used within the product',
		url: 'https://www.figma.com/file/eNf6VgmY250UaEAxE9kaUl/Illustrations-Library?node-id=0%3A1',
		icon: 'brush'
	},
	{
		title: 'Icons',
		subtitle: 'From Material icons',
		url: 'https://www.figma.com/file/YN0ukVqAnKgVCqvih53757/Icons',
		icon: 'border_clear'
	},
	{
		title: 'Fonts',
		subtitle: 'Downloads for fonts used in the app',
		url: '/foundations/typography/#additional-information',
		icon: 'font_download',
		internal: true
	}
];

const devResources = [
	{
		title: 'Anvil Storybook',
		subtitle: 'UI Component Explorer',
		url: 'https://anvil.st.dev/',
		icon: 'code'
	},
	{
		title: 'GitHub Repository',
		subtitle: 'Source code of project',
		url: 'https://github.com/servicetitan/anvil',
		icon: "device_hub"
	},
	{
		title: 'Changelog',
		subtitle: 'Release history of Anvil',
		url: 'https://github.com/servicetitan/anvil/releases',
		icon: "change_history"
	},
	{
		title: 'Code Contribution Guidelines',
		subtitle: 'Bug logging, code processes',
		url: 'https://github.com/servicetitan/anvil/blob/v7.8.1/CONTRIBUTING.md'
	}
];

const connect = [
	{
		title: 'Report an Issue',
		description: 'Ran into a bug in the code or design? We use Jira to track outstanding issues with Anvil.',
		icon: 'report_problem',
		link: 'https://servicetitan.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=11329',
		linkName: 'File an issue on Jira'
	},
	{
		title: 'Get Help',
		description: 'You can reach the Anvil team in the #ask-designsystem Slack channel.',
		icon: 'help',
		link: 'https://servicetitan.slack.com/archives/CBSRGHTRS',
		linkName: 'Join the Slack channel'
	},
	{
		title: 'Suggest an Idea',
		description: 'Have an idea for a component, pattern, or documentation? Submit your idea to Jira.',
		icon: 'lightbulb_outline',
		link: 'https://servicetitan.atlassian.net/secure/CreateIssue.jspa?issuetype=11597&pid=11329',
		linkName: 'Add an idea on Jira'
	},
	{
		title: 'Anvil Roadmap',
		description: 'We update our roadmap every quarter with what we’re working on.',
		icon: 'map',
		link: 'https://servicetitan.atlassian.net/jira/software/c/projects/ANV/boards/766/roadmap',
		linkName: 'View the roadmap'
	},
];

const Homepage = () => {
	const { size } = React.useContext(ScreenSizeContext);
	const [isMobile, setIsMobile] = React.useState();

	React.useEffect(() => {
		setIsMobile(size.smallerThan.mobile);
	}, [size]);

	const linkArrow = <Icon name="arrow_forward" className="position-relative" style={{ top: '-2px' }} />;

	const layoutSpacing = isMobile ? "compact" : "relaxed";
	const pageXSpacing = isMobile ? "m-x-auto p-x-3" : "m-x-auto p-x-5";
	const cardSpacing = isMobile ? "" : "p-5";

	return (
		<GlobalWrapper>
			<SEO />
			<Page
				className="intro"
                spacing='none'
                maxWidth='wide'
                header={
                    <div className="bg-white" style={{ borderBottom: '1px solid #dfe0e1' }}>
						{/* Hero */}
                        <div className={`${pageXSpacing} ${isMobile ? "p-y-4" : "p-y-8"}`} style={{ maxWidth: '1280px' }}>
							<Stack justifyContent={isMobile ? "flex-start" : "space-between"} direction={isMobile ? "column" : "row"} spacing={4}>
								<Stack direction="column" style={{ maxWidth: '430px', flexBasis: !isMobile && "430px" }} spacing={1} >
									<Headline style={{ fontSize: isMobile ? tokens.typescale7 : tokens.spacing6 }}><div>{hero.title[0]}</div><div className="m-l--half" /* Cheating hard on Sofia Pro font alignment */ >{hero.title[1]}</div></Headline>
									<BodyText size="large" className="p-t-1">{hero.tagline}</BodyText>
									<BodyText>{hero.description}</BodyText>
								</Stack>
								<Stack.Item className="position-relative" style={{ width: '590px', height: '372px' }}>
									<img src={heroIllustration} alt="Anvil Hero illustration" className="max-h-100 max-w-100 h-auto w-auto position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
								</Stack.Item>

							</Stack>
                        </div>
                    </div>
                }
			>
				<div className="bg-neutral-30 w-100 m-t--2 border-bottom">

					{/* What's new */}
					<div className={`${pageXSpacing} p-t-2 m-t--6 pos-relative z-overlay`} style={{ maxWidth: '1280px' }}>
						<Layout spacing={layoutSpacing}>
							<Card className="w-100">
								<Stack>
									<Stack.Item style={{ flexBasis: '378px' }} className={isMobile ? "" : "m-l-3"}>
										<Eyebrow>What's new</Eyebrow>
										<Headline className="m-b-0">{whatsNew.date}</Headline>
									</Stack.Item>
									<Stack.Item>
										{whatsNew.description}
										<Link href={whatsNew.link} primary className="d-b">See all updates {linkArrow}</Link>
									</Stack.Item>
								</Stack>
							</Card>
						</Layout>
					</div>


					<div className={`${pageXSpacing} p-b-7 p-y-6`} style={{ maxWidth: '1280px' }}>

						{/* Offerings */}
						<Layout type="3Col" className="p-b-7" spacing={layoutSpacing}>
							{offerings.map((offering, index) => (
								<Layout.Section style={{ maxWidth: isMobile ? '100%' : '288px', flexBasis: '288px' }} key={index}>
									<Card>
										<Card.Section className={cardSpacing}>
											<img
												src={offering.illustration}
												alt="Anvil Hero illustration"
												className="max-h-100 max-w-100 h-auto w-auto position-relative m-b-half"
												style={{
													maxWidth: offering.maxWidth,
													top: !isMobile && offering.top, // offset 'out of box' effect of illustrations
													left: !isMobile && offering.left, // offset 'out of box' effect of illustrations
													height: '124px', // keeps columns equal in height
													objectFit: 'scale-down' // keeps proportions equal
												}}
											/>
											<Headline size="large" className="m-b-1">{offering.title}</Headline>
											<BodyText className="p-t-half">{offering.description}</BodyText>
											<Link href={offering.link} primary className="d-b m-t-3">Explore {offering.title} {linkArrow}</Link>
										</Card.Section>
									</Card>
								</Layout.Section>
							))}
						</Layout>

						{/* Resources */}
						<Layout type="2Col" spacing={layoutSpacing}>
							<Layout.Section>
								<Card>
									<Card.Section className={cardSpacing}>
										<Headline size="large">Resources for Designers</Headline>
										<BodyText className="p-t-half m-b-3">Anvil provides many Figma design tools, including components.</BodyText>
										{designResources.map((resource, index) => (
											<ResourceLink
												title={resource.title}
												subtitle={resource.subtitle}
												url={resource.url}
												externalUrl={!resource.internal}
												iconName={resource.icon}
												key={index}
											/>
										))}
										<Link href='/resources' primary className="m-t-4 d-b">View more resources {linkArrow}</Link>
									</Card.Section>
								</Card>
							</Layout.Section>
							<Layout.Section>
								<Card>
									<Card.Section className={cardSpacing}>
										<Headline size="large">Resources for Developers</Headline>
										<BodyText className="p-t-half m-b-3">Anvil is built in React, ready to be used in the app.</BodyText>
										{devResources.map((resource, index) => (
											<ResourceLink
												title={resource.title}
												subtitle={resource.subtitle}
												url={resource.url}
												externalUrl
												iconName={resource.icon}
												key={index}
											/>
										))}
										<Link href='/resources' primary className="m-t-4 d-b">View more resources {linkArrow}</Link>
									</Card.Section>
								</Card>
							</Layout.Section>
						</Layout>

					</div>
				</div>

				{/* Connect with Us */}
				<div className={`${pageXSpacing} p-b-3  p-y-7`} style={{ maxWidth: '1280px' }}>
					<Layout type="support" direction="left" spacing={layoutSpacing}>
						<Layout.Section>
							<Headline size="xlarge">Connect with Us</Headline>
							<BodyText className="p-t-1 m-b-6" style={{ maxWidth: '350px' }}>Need help with Anvil? Found something wrong? Reach out to us for your need.</BodyText>
						</Layout.Section>

						<Layout.Section><Stack wrap="wrap">
							{connect.map((c, index) => (
								<Stack.Item style={{ flexBasis: '300px' }} grow={1} className="m-b-6" key={index}>
									<Stack alignItems="center">
										<Icon name={c.icon} size="24px" className="m-r-1 p-r-half c-neutral-90" />
										<Headline className="m-0" size="large">{c.title}</Headline>
									</Stack>
									<BodyText className="p-l-half m-l-4 m-t-2 m-b-3">{c.description}</BodyText>
									<Link href={c.link} primary className="p-l-half m-l-4">{c.linkName}</Link>
								</Stack.Item>
							))}
						</Stack></Layout.Section>
					</Layout>
				</div>

				{!isMobile && <Footer />}

			</Page>
		</GlobalWrapper>
	)
}

export default Homepage;
